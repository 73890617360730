import { useEffect, useState } from 'react';

import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';
import { DeviceSubscription } from '../../models/Device';

import Button from '@mui/material/Button';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation, useQueryClient } from 'react-query';

import { DialogContentText } from '@mui/material';
import { User } from '../../models/User';

interface RequestAppFeedbackMutationFnParams { 
  userId: string;
}

const requestAppFeedbackMutationFn = async ({ userId }: RequestAppFeedbackMutationFnParams): Promise<{}> => {
    const response = await ShieldGPSAxiosClient.post<DeviceSubscription>(
      '/admin/user-search/app-feedback-requests', {
        userId,
      });
  
    return response.data;
  };

export interface RequestAppFeedbackDialogParams {
  open: boolean,
  user: User,
  handleClose: () => void,
};

export const RequestAppFeedbackDialog = ({ open, user, handleClose }: RequestAppFeedbackDialogParams) => {
    const queryClient = useQueryClient();
    const [ email, setEmail ] = useState('');

    // When submitting reactivation
    const requestAppFeedbackMutation =  useMutation({
      mutationFn: requestAppFeedbackMutationFn,
      onSuccess: () => {
        console.log(`invalidating query: user-lookup ${user.id}`);
        queryClient.invalidateQueries({ queryKey: ['user-lookup', user.id.toString()] })
        handleClose();
      }
    });
  
    const handleConfirm = () => {
      requestAppFeedbackMutation.mutate({ userId: user.id.toString() });
    }
  
    // Clear state when dialog is closed
    useEffect(() => {
      if (!open) {
        requestAppFeedbackMutation.reset();   
      }
    }, [open]);  

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Request App Feedback?
        </DialogTitle>

        <DialogContent>
            <DialogContentText>
            This will trigger an in-app notification asking <strong>{user?.email}</strong> to rate their experience.
            </DialogContentText>
          {requestAppFeedbackMutation.isError && (
          <Alert severity="error">Failed to request app feedback.</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton onClick={handleConfirm} loading={requestAppFeedbackMutation.isLoading} color="primary" variant="contained" autoFocus>
            Send Request
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }