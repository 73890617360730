import { useContext } from 'react';
import Navbar from './components/Navbar';
import AuthProvider from './lib/auth/AuthProvider';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';

import { QueryClient, QueryClientProvider } from 'react-query';
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import AuthContext from './lib/auth/AuthContext';
import { ActivationsFeedPage } from './pages/ActivationsFeed/ActivationsFeedPage';
import { DeviceViewPage } from './pages/DeviceView/DeviceViewPage';
import { OrdersFeedPage } from './pages/OrdersFeed/OrdersFeedPage';
import { OrderViewPage } from './pages/OrderView/OrderViewPage';
import { UserViewPage } from './pages/UserView/UserViewPage';

const queryClient = new QueryClient();

function DevicesLayout() {
  return (
    <div className="devicesLayout">
      <Outlet />
    </div>
  );
}

function AuthenticatedApp({ children }: { children: any }) {
  const {isAuthenticated, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  if (!isAuthenticated) {
    return <LoginPage />
  }

  return children;
}

function App() {

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <AuthenticatedApp>
            <Router>
              <Navbar />
              <div style={{marginTop: '70px'}}>
                <Routes>
                  <Route path="/" element={ <HomePage />} />
                  <Route path="devices" element={<DevicesLayout />} >
                    <Route path=":imei" element={<DeviceViewPage />} />
                    <Route index element={<Navigate to="/" replace />} />
                  </Route>
                  <Route path="users" element={<DevicesLayout />} >
                    <Route path=":userId" element={<UserViewPage />} />
                    <Route index element={<Navigate to="/" replace />} />
                  </Route>
                  <Route path="activations" element={<DevicesLayout />} >
                    <Route index element={ <ActivationsFeedPage />} />
                  </Route>
                  <Route path="orders" element={<DevicesLayout />} >
                    <Route path=":orderId" element={<OrderViewPage />} />
                    <Route index element={ <OrdersFeedPage />} />
                  </Route>
                </Routes>
                </div>
            </Router>
          </AuthenticatedApp>
        </div>
      </QueryClientProvider>
    </AuthProvider>
  );
}


export default App;
