import { DeviceActivationEvent } from "./Device";
import { Order } from "./Order";

export enum CancelWhen {
  IMMEDIATELY = "immediately",
  PERIOD_END = "period_end",
};

export interface CancelDeviceRequest {
  subscriptionId: string;
  cancelWhen: CancelWhen;
};

export interface ActivationsFeedResponse {
  events: DeviceActivationEvent[];
  totalCount: number;
  totalPages: number;
}

export type OrdersFeedResponse = {
  orders: Order[];
  totalCount: number;
  totalPages: number;
}