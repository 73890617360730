import styled from "styled-components";

import { useMemo } from "react";
import { FaLink } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  formatAsDateAndTime,
  LinkIndicator,
  OrderStatusBadge,
  SmallText,
  Text
} from "../../components/Common";
import { DeviceImage } from "../../components/device/DeviceImage";
import {
  describeMarketplace,
  describeOrderItems,
  mapAmazonSku,
} from "../../lib/order-common";
import { Order } from "../../models/Order";
import { ChannelIcon } from "../ChannelIcon";

const OrderRowContainer = styled.div`
  display: flex;
  padding: 10px 5px 10px 5px;
  align-items: center;
  &:first-child {
    border-top: #d8d8d8 1px solid;
  }
  border-bottom: #d8d8d8 1px solid;
  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
`;

const OrderTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 28px;
`;

const OrderTitleLeft = styled.div`
  display: flex;
  align-items: center;
`;

const OrderTags = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const OrderInfoMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-start;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 5px;
`;

const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OrderRow = ({ order }: { order: Order }) => {
  const navigate = useNavigate();

  const itemsDescription = useMemo(() => describeOrderItems(order), [order]);

  return (
    <OrderRowContainer onClick={() => navigate(`/orders/${order.id}`)}>
      <DeviceImage
        deviceModel={mapAmazonSku(order.order_items[0].sku)}
        size={60}
      />
      <OrderInfoMain>
        <OrderTitleRow>
          <OrderTitleLeft>
            <ChannelIcon channel={order.channel} />
            <Text>
              <strong>{order.channel_order_id}</strong>
            </Text>
          </OrderTitleLeft>
          {order.has_linked_devices && (
            <LinkIndicator title="Linked to user">
              <FaLink size={16} />
            </LinkIndicator>
          )}
        </OrderTitleRow>
        <SmallText>{itemsDescription}</SmallText>
        <SpacedRow>
          <OrderTags>
            <OrderStatusBadge order={order} />
            {describeMarketplace(order.channel, order.marketplace_id)}
          </OrderTags>
          <SmallText>{formatAsDateAndTime(order.purchase_at)}</SmallText>
        </SpacedRow>
      </OrderInfoMain>
    </OrderRowContainer>
  );
};
