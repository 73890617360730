import { useQuery } from "react-query";
import styled from "styled-components";
import ShieldGPSAxiosClient from "../../lib/ShieldGPSAxiosClient";

import { useState } from "react";
import { LoadingOverlay } from "../../components/Common";
import { OrderRow } from "../../components/order/OrderRow";
import { OrdersFeedResponse } from "../../models/Requests";
import { PaginationControls } from "./PaginatedControls";

const PAGE_SIZE = 50;

export const Content = styled.div`
  text-align: left;
  padding: 0 1em;
  max-width: 800px;
  margin: auto;
`;

const OrdersListContainer = styled.div`
  position: relative;
`;

const fetchOrdersFeed = async (
  page = 1,
  limit = 50
): Promise<OrdersFeedResponse> => {
  const response = await ShieldGPSAxiosClient.get<OrdersFeedResponse>(
    "/admin/orders",
    { params: { page, limit } }
  );

  return response.data;
};

export function OrdersFeedPage() {
  const [page, setPage] = useState(1);

  // isPreviousData is true usually only briefly when the last queries
  // data is being displayed because the actual requested data is inflight
  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ["orders-feed", page],
    () => fetchOrdersFeed(page, PAGE_SIZE),
    {
      keepPreviousData: true,
    }
  );

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    if (data && page < data.totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div>
      <h1>Orders Feed</h1>
      <Content>
        <PaginationControls
          currentPage={page}
          totalPages={data?.totalPages || 1}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
        />

        <OrdersListContainer style={{ minHeight: "100px" }}>
          {isLoading ? (
            <LoadingOverlay />
          ) : data?.totalCount === 0 ? (
            <p>No orders found</p>
          ) : (
            <>
              {isFetching && isPreviousData && <LoadingOverlay />}
              <ol start={(page - 1) * PAGE_SIZE + 1}>
                {data?.orders.map((order) => (
                  <OrderRow key={order.id} order={order} />
                ))}
              </ol>
            </>
          )}
        </OrdersListContainer>

        <PaginationControls
          currentPage={page}
          totalPages={data?.totalPages || 1}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
        />
      </Content>
    </div>
  );
}
