import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  LeftColumn,
  MainContainer,
  RightColumn,
} from "../../components/Common";
import ShieldGPSAxiosClient from "../../lib/ShieldGPSAxiosClient";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";

import CircularProgress from "@mui/material/CircularProgress";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { useParams } from "react-router-dom";
import { Order } from "../../models/Order";
import { OrderProperties } from "./OrderProperties";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

/**
 * Actions menu.
 *
 * @param param0
 * @returns
 */
function OrderActionsDropdown({
  onActionSelect,
}: {
  onActionSelect: (action: string) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        variant="contained"
        endIcon={<KeyboardArrowDownRoundedIcon />}
        aria-haspopup="menu"
      >
        Actions
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper>
          <MenuList>
            <MenuItem
              key={1}
              disabled={true}
              onClick={() => {
                handleClose();
                onActionSelect("solicitReview");
              }}
            >
              Request Review
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
}

const getOrder = async (orderId: string | undefined): Promise<Order | null> => {
  if (!orderId) {
    return null;
  }

  try {
    const response = await ShieldGPSAxiosClient.get<Order>(
      `/admin/orders/${orderId}`
    );
    return response.data;
  } catch (error: any) {
    if (error.request.status === 404) {
      // No order
      return null;
    }

    // Just rethrow
    throw error;
  }
};

export function OrderViewPage() {
  const { orderId } = useParams();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const {
    data: order,
    isLoading: orderIsLoading,
    isError,
  } = useQuery(["order-lookup", orderId], () => getOrder(orderId), {
    retry: false,
  });

  const handleActionSelect = (action: string) => {
    switch (action) {
      case "cancel": {
        // do something..
        break;
      }
    }
  };

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setCurrentTab(newValue);
    },
    [setCurrentTab]
  );

  return (
    <>
      <MainContainer>
        {orderIsLoading && <CircularProgress />}

        {!orderIsLoading && !order && isError && (
          <p>
            Error whilst loading order: <strong>{orderId}</strong>.
          </p>
        )}

        {!orderIsLoading && !order && !isError && (
          <p>
            Order not found: <strong>{orderId}</strong>.
          </p>
        )}

        {!!order && !orderIsLoading && (
          <>
            <LeftColumn>
              <OrderProperties order={order} />
            </LeftColumn>

            <RightColumn>
              <Box style={{ textAlign: "right" }}>
                <OrderActionsDropdown onActionSelect={handleActionSelect} />
              </Box>
              <Box>
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="Overview" />
                </Tabs>
              </Box>

              <TabPanel value={currentTab} index={0}>
                <p>Order Overview...</p>
              </TabPanel>
            </RightColumn>
          </>
        )}
      </MainContainer>
    </>
  );
}
