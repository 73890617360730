import { Order, OrderChannel } from "../models/Order";

interface MarketPlaceProps {
  label: string;
  orderUrl: string;
}
export const AMAZON_MARKETPLACES: Record<string, MarketPlaceProps> = {
  // UK FBA
  'A1F83G8C2ARO7P': {
    label: '🇬🇧',
    orderUrl: 'https://sellercentral.amazon.co.uk/orders-v3/order/',
  },

  // UK MCF
  'AZMDEXL2RVFNN': {
    label: '🇬🇧 (MCF)',
    orderUrl: 'https://sellercentral.amazon.co.uk/orders-v3/order/',
  },

  // US FBA
  'ATVPDKIKX0DER': {
    label: '🇺🇸',
    orderUrl: 'https://sellercentral.amazon.com/orders-v3/order/',
  }
};

export const MARKETPLACE_URLS: Record<string, string> = {

}

// Map Amazon SKU to our internal product model
const AMAZON_SKU_MAP: Record<string, string> = {
  VO1: "GV500MA",
  "AT1.1": "AT1",
  "OB22.": "OB22",
  "OB22.1": "OB22",
};

export const mapAmazonSku = (sku: string): string => {
  if (sku in AMAZON_SKU_MAP) {
    return AMAZON_SKU_MAP[sku];
  }

  // Otherwise return the original SKU
  return sku;
};

export const describeMarketplace = (
  channel: OrderChannel,
  marketplaceId: string
) => {
  if (channel === "amazon") {
    const marketplaceProps = AMAZON_MARKETPLACES[marketplaceId];
    if (marketplaceProps) {
      return marketplaceProps.label;
    }
  }

  return "";
};

export const describeOrderItems = (order: Order): string => {
  return order.order_items
    .map((item) => `${item.sku} x ${item.quantity}`)
    .join(", ");
};

/**
 * Return the Seller Central URL for an order.
 * 
 * @param order 
 * @returns 
 */
export const getOrderMarketplaceUrl = (order: Order): string => {
  const marketplaceProps = AMAZON_MARKETPLACES[order.marketplace_id];

  if (!marketplaceProps) {
    return '';
  }

  return marketplaceProps.orderUrl + order.channel_order_id;
}