import { FaLink } from "react-icons/fa";
import {
  BiggestText,
  Box,
  ClickToCopy,
  FieldDescription,
  formatAsDateAndTime,
  LinkIndicator,
  OrderStatusBadge,
  SectionHeaderText,
  Text,
} from "../../components/Common";
import { DeviceImage } from "../../components/device/DeviceImage";
import {
  describeMarketplace,
  describeOrderItems,
  getOrderMarketplaceUrl,
  mapAmazonSku,
} from "../../lib/order-common";
import { Order } from "../../models/Order";

/**
 * Renders an info panel that describes all key properties of a given Order.
 */
interface OrderPropertiesProps {
  order?: Order;
}

export const OrderProperties = ({ order }: OrderPropertiesProps) => {
  if (!order) {
    return null;
  }

  const channelName = order.channel
    ? order.channel.charAt(0).toUpperCase() + order.channel.slice(1)
    : "Uknown";

  return (
    <div>
      <Box>
        <BiggestText>{channelName} Order</BiggestText>
        <ClickToCopy copyContent={order?.channel_order_id || ""}>
          <Text>{order?.channel_order_id}</Text>
        </ClickToCopy>
      </Box>

      <Box style={{ position: 'relative' }}>
        <DeviceImage
          deviceModel={mapAmazonSku(order.order_items[0].sku)}
          size={250}
        />
        {order.has_linked_devices && (
          <LinkIndicator title="Linked to user" style={{ position: 'absolute', right: 0, bottom: 0 }}>
            <FaLink size={16} />
          </LinkIndicator>
        )}
      </Box>

      <Box>
        <SectionHeaderText>Details</SectionHeaderText>
        <FieldDescription name="Our ID">
          <ClickToCopy copyContent={order.id}>
            {order.id.substring(0, 6)}
          </ClickToCopy>
        </FieldDescription>

        <FieldDescription name={channelName + " ID"}>
          <a
            href={getOrderMarketplaceUrl(order)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {order.channel_order_id}
          </a>
        </FieldDescription>

        <FieldDescription
          name="Purchased At"
          value={formatAsDateAndTime(order.purchase_at)}
        />

        <FieldDescription name="Status">
          <OrderStatusBadge order={order} />
        </FieldDescription>

        <FieldDescription name="Marketplace">
          <span>
            {order.marketplace_id}{" "}
            {describeMarketplace(order.channel, order.marketplace_id)}
          </span>
        </FieldDescription>

        <FieldDescription
          name="Quantity"
          value={order.total_items_quantity + ""}
        />
        <FieldDescription name="Review Requested" />

        <FieldDescription name="Summary" value={describeOrderItems(order)} />
      </Box>
    </div>
  );
};
