import React from "react";
import styled from "styled-components";
import { OrderChannel } from "../models/Order";
import { FaAmazon, FaShopify, FaQuestion} from 'react-icons/fa';

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
`;

interface ChannelIconProps {
  channel: OrderChannel;
}

export const ChannelIcon: React.FC<ChannelIconProps> = ({ channel }) => {
  const renderIcon = () => {
    switch (channel) {
      case 'amazon':
        return <FaAmazon size={20} color="#414552" />;
      case 'shopify':
        return <FaShopify size={20} color="#414552" />;
      default:
        return <FaQuestion size={20} color="#414552" />;
    }
  };

  return (
    <IconWrapper>
      {renderIcon()}
    </IconWrapper>
  );
};
