import React from 'react';

interface UserRatingProps {
  rating: number | null;
  noRatingText?: string;
}

export const UserRating: React.FC<UserRatingProps> = ({ rating, noRatingText}) => {
  // Function to determine which emoji to display based on rating
  const getRatingEmoji = (rating: number) => {
    if (rating <= 1) return '😡'; // Very unhappy
    if (rating <= 2) return '😕'; // Unhappy
    if (rating <= 3) return '😐'; // Neutral
    if (rating <= 4) return '🙂'; // Happy
    return '😄'; // Very happy
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
      {rating ? (
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '20px', color: '#414552'}}>
          {[...Array(5)].map((_, index) => <span key={index}>{index < rating ? '★' : '☆'}</span>)}
          <span style={{ marginLeft: '8px', fontSize: '14px', color: '#757575' }}>
            ({rating}/5)
          </span>
          <span style={{ marginLeft: '8px', fontSize: '20px' }}>{getRatingEmoji(rating)}</span>
        </div>
      ) : (
        <span style={{ fontSize: '16px', color: '#757575' }}>{noRatingText}</span>
      )}
    </div>
  );
};
