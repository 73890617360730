import { useQuery } from "react-query";
import styled from "styled-components";
import ShieldGPSAxiosClient from "../../lib/ShieldGPSAxiosClient";

import { DeviceRow } from "../../components/device/DeviceRow";
import { ActivationsFeedResponse } from "../../models/Requests";
import { useState } from "react";
import { PaginationControls } from "../OrdersFeed/PaginatedControls";
import { LoadingOverlay } from "../../components/Common";

const PAGE_SIZE = 50;

export const Content = styled.div`
  text-align: left;
  padding-left: 0 1em;
  max-width: 800px;
  margin: auto;
`;

const ActivationsListContainer = styled.div`
  position: relative;
`;

const fetchActivationsFeed = async (
  page = 1,
  limit = 50
): Promise<ActivationsFeedResponse> => {
  const response = await ShieldGPSAxiosClient.get<ActivationsFeedResponse>(
    "/admin/user-search/activations-feed?event_type=activated",
    { params: { page, limit } }
  );

  return response.data;
};

export function ActivationsFeedPage() {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ["activations-feed", page],
    () => fetchActivationsFeed(page, PAGE_SIZE),
    {
      keepPreviousData: true,
    }
  );

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    if (data && page < data.totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div>
      <h1>Activations Feed</h1>
      <Content>
        <PaginationControls
          currentPage={page}
          totalPages={data?.totalPages || 1}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
        />

        <ActivationsListContainer style={{ minHeight: "100px" }}>
          {isLoading ? (
            <LoadingOverlay />
          ) : data?.totalCount === 0 ? (
            <p>No orders found</p>
          ) : (
            <>
              {isFetching && isPreviousData && <LoadingOverlay />}
              <ol start={(page - 1) * PAGE_SIZE + 1}>
                {data?.events.map((event) => (
                  <DeviceRow key={event.id} device={event.device} />
                ))}
              </ol>
            </>
          )}
        </ActivationsListContainer>

        <PaginationControls
          currentPage={page}
          totalPages={data?.totalPages || 1}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
        />
      </Content>
    </div>
  );
}
